import React from 'react'
import { Link } from "gatsby"
import Layout from '../components/Layout'
import Form from '../components/Form'
import * as styles from '../styles/contact.module.css'
import * as mobileStyles from '../styles/mobile/contact.module.css'
import { FaPhone, FaEnvelope, FaFacebookSquare } from 'react-icons/fa';
import { useWindowDimensions } from '../components/Window'
import Seo from '../components/SEO'

const Contact = () => {
  const { width } = useWindowDimensions()

  let thisStyle;
  if (width > 850) thisStyle = styles;
  else thisStyle = mobileStyles;

  return (
    <Layout>
      <Seo title={`Contact Us`} />
      <h1 className={thisStyle.details}>Have any further questions?</h1>
      <div className={thisStyle.email_phone}>
        <Link href="sms:778-834-4955" className={thisStyle.link}>
          <FaPhone size="28px" />
          778-834-4955
        </Link>
        <Link href="mailto: alvin.aceja@gmail.com" className={thisStyle.link}>
          <FaEnvelope size="28px" />
          alvin.aceja@gmail.com
        </Link>
        <Link href="https://www.facebook.com/pg/Smart-Safe-Driving-School-408598745929030/"
          className={thisStyle.link}>
          <FaFacebookSquare size="28px" />
          Facebook
        </Link>
      </div>
      <div className={thisStyle.text}>
        I respond more quickly to text messages or phone calls.
        Let me know about your prior experience, road test date, and availability.
        If we can find an agreement, I will confirm our appointment by text.
      </div>
      <Form />
    </Layout>
  )
}

export default Contact