import React from "react"
import * as styles from "../styles/Form.module.css"
import * as mobileStyles from '../styles/mobile/form.module.css'
import { useForm } from 'react-hook-form'
import { useWindowDimensions } from '../components/Window'
import { navigate } from "gatsby"

function HookForm() {
  const { width } = useWindowDimensions()

  let thisStyle;
  if (width > 850) thisStyle = styles;
  else thisStyle = mobileStyles;

  const { register, handleSubmit, reset, formState } = useForm({ mode: 'onChange' })

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const onSubmit = (data, event) => {
    event.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data })
    })
      .then(() => {
        navigate('/formSuccess');
        reset({
          email: "",
          fullName: "",
          phone: "",
          message: ""
        }, {
          keepErrors: true,
          keepDirty: true,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false,
        });
        console.log(JSON.stringify(data));
      })
      .catch((error) => {
        console.log("error");
      });
  }

  return (
    <form
      className={thisStyle.form}
      onSubmit={handleSubmit(onSubmit)}
      name="contact"
      method="POST"
      action="/formSuccess"
      netlify-honeypot="botField"
      data-netlify="true">
      <input type="hidden" name="form-name" value="contact" />
      <input type="text" name="botField" placeholder="If you are human, do not fill this out." className={thisStyle.honeyPot} />
      <input {...register("email", { required: true })}
        type="text" name="email" placeholder="Email" className={thisStyle.field} />
      <input {...register("fullName", { required: true })}
        type="text" name="fullName" placeholder="Full Name" className={thisStyle.field} />
      <input {...register("phone", { required: true })}
        type="text" name="phone" placeholder="Phone Number" className={thisStyle.field} />
      <textarea {...register("message", { required: true })}
        type="text" name="message" placeholder="Message" className={`${thisStyle.field} ${thisStyle.message}`} />
      <button type="submit" disabled={!formState.isValid}>Submit</button>
    </form>
  )
}

export default class Form extends React.Component {

  render() {
    return (
      <HookForm />
    )
  }
}